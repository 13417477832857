<template>
  <div class="container main-width detail-container news-table">
    <a-skeleton active :loading="isLoading">
      <div class="d-flex mb-40" :class="['table-title-container-border']">
        <div
          class="fs-36 table-title text-elite-blue fw-bold pb-2 fs-28-md fs-21-sm"
          :class="['table-title-border']"
        >
          {{ data?.title }}
        </div>
      </div>
      <div>
        <div class="fs-16 text-elite-blue my-3">
          {{ data?.content }}
        </div>
        <div class="my-3">
          <div v-for="(file, index) in data?.files" :key="file" class="d-flex">
            <div
              class="rounded-3 border-1 border-dark-gray py-3 px-4 bg-light cursor-pointer text-cobalt"
              :class="index && 'mt-3'"
              @click="handleClickFile(file)"
            >
              <i class="far fa-file me-1"></i>
              {{ getFileName(file.file) }}
            </div>
          </div>
        </div>
        <div class="fs-16 text-elite-blue my-3">
          {{ data?.created_at }}
        </div>
      </div>
    </a-skeleton>
  </div>
</template>

<script>
  import { defineComponent } from 'vue'

  import { news, taskForce, announcements } from '@/service'

  export default defineComponent({
    data() {
      return {
        data: null,
        isLoading: true,
      }
    },
    created() {
      let request = news.getDetail

      if (this.$route.path.includes('/announcements')) {
        request = announcements.getDetail
      } else if (this.$route.path.includes('/examination-task-force')) {
        request = taskForce.getRostersDetail
      }

      request(this.$route.params.id)
        .then(({ data }) => {
          this.data = data
        })
        .finally(() => (this.isLoading = false))
    },
    methods: {
      getFileName(fileName) {
        return decodeURIComponent(fileName.split('/').pop())
      },
      handleClickFile({ file }) {
        window.open(file)
      },
    },
  })
</script>

<style lang="scss" scoped>
  .table-title-container-border {
    border-bottom: 3px solid #e5ebf5;
  }

  .table-title-border {
    box-shadow: 0px 3px #1042a4;
    white-space: nowrap;
  }

  .table-title {
    line-height: 100%;
  }

  .bg-light:hover {
    background-color: #d9e2f3 !important;
  }
</style>
